.tools {
    display: flex;
    flex-direction: column;
    background-color: var(--element-bg);
    width: 100vw;
    padding-top: 12px;
    padding-bottom: 12px;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
    h1 {
        width: 75vw;
    }
    p {
        text-align: center;
    }
}
