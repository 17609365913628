.career {
  display: flex;
  flex-direction: column;
  background-color: var(--element-bg);
  width: 100vw;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: auto;

  p {
    text-align: center;
    display: inline;
  }

  h1 {
    width: 75vw;
    display: flex;
    flex-direction: row;

    p {
      margin: 1vw;
    }

    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.careerexp {
  p {
    width: 75vw;
    text-align: justify;
    display: inline;
  }

  .segment {
    display: flex;
    width: 80%;
    justify-content: space-between;
    flex-direction: row;
    .titledate {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 0;
      justify-content: left;
      align-items: flex-start;
      h2 {
        display: flex;
        font-size: 3vh;
        align-items: flex-start;
        justify-content: left;
      }

      .date {
        color: grey;
      }
    }
    div {
      flex: 2;
    }
  }

  display: flex;
  flex-direction: column;
  background-color: var(--element-bg);
  width: 100vw;
  padding-top: 12px;
  padding-bottom: 12px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
