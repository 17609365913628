.managment {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .login {
        display: flex;
        margin: 2vh;
    }
    .request {
        display: flex;
        width: 80%;
        justify-content: space-evenly;
    }
    .block {
        display: flex;
    }
}