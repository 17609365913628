.post {
  margin-bottom: 5vh;
  background-color: var(--element-bg);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2vh;
  padding-bottom: 5vh;
  img {
    margin: 1vw;
    border-radius: 2vw;
    width: 32vw;
    height: 32vw;

    @media (min-width: 1025px) {
      width: 25%;
      height: auto;
    }
  }

  @media (min-width: 1025px) {
    flex-direction: row;
  }
}

.content {
  .titleBar {
    display: flex;
    flex-direction: column;
    @media (min-width: 1025px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  width: 65%;
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;
    @media screen and (max-width: 1024px) {
      padding-left: 0px;
    }
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: 0;
  }
  li {
    list-style: none;
    margin-left: 3vw;
  }
}

.projectDescription {
  h3 {
    color: lightgray;
  }
  p {
    color: grey;
  }
  a {
    font-style: none;
    text-decoration: none;
    color: #363738;
  }
  a:hover {
    animation: lightup 0.7s ease-in;
    color: grey;
  }
}

@keyframes lightup {
  0% {
    color: #363738;
  }
  100% {
    color: grey;
  }
}
