.description {
    display: flex;
    flex-direction: column;
    background-color: var(--element-bg);
    width: 100vw;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: auto;
    img {
        border-radius: 50%;
        margin: 3vh;
        height: 35vh;
    }
    p {
        line-height: 4vmin;

        p {
            padding-left: 3px;
            padding-right: 3px;
            white-space: nowrap;
        }
        display: inline;
        width: 66vw;
        font-size: calc(5px + 2vmin);
    }

    h1 {
        margin: 0;
    }

}