.tutoring {
    display: flex;
    flex-direction: column;
    background-color: var(--element-bg);
    width: 100vw;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: auto;

    p {
        width: 75vw;
    }
}

.courses_list {
    display: flex;
    flex-direction: column;

    a {
        list-style: none;
        margin-left: 8px;
        margin-right: 8px;
        margin-top: 10px;

        @media (min-width:1025px) {
            margin-right: 20px;
            margin-left: 0px;
            margin-top: 25px;
        }

        font-size: 30px;
        font-weight: 300;
        cursor: pointer;
        border: 1px solid #fff;
        color: #fff;
        border-radius: 8px;
        padding: 5px;
    }

    a:hover {
        background-color: rgba(131, 131, 131, 0.5);
    }
}