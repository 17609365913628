.App {
  text-align: center;
}
:root {

  --element-bg: #141414;
  --bar-bg: #282828;
}


.App-header {
  overflow: hidden;
  background-color: var(--bar-bg);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a {
  text-decoration: none;
}

